import React from 'react';
import PropTypes from 'prop-types';
import { PromoBoxComponent } from './PromoBox.styles.js';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Text, RichText } from 'components/shared/JssOverrides';
import { useDeviceResize } from 'hooks';
import { underlineText } from 'helpers/stringHelpers';

import Button from 'components/shared/Button';

const PromoBox = ({
  variant = 'red',
  title,
  titleClass = 'h1',
  text,
  copy,
  cta,
  backgroundColor,
  titleColor,
  copyColor,
  ctaColor,
  cssClass,
  onClick,
  children,
  className,
  headingClass,
  textClass,
  pageEditing = false,
  underlineTitle = false,
  sitecoreContext: { site={} },
}) => {
  const device = useDeviceResize();
  const ctaVisible = !!(cta?.hasAction || cta?.value?.href?.length);
  const headingTag = cssClass === 'hero-bg-lg' ? 'h1' : 'h2';


  return (
    <PromoBoxComponent
      variant={backgroundColor || variant}
      titleColor={titleColor}
      copyColor={copyColor}
      backgroundColor={backgroundColor}
      className={`${className} ${cssClass} ${site.name === 'bushs-beans-foodservices' ? 'fs-promobox' : ''}`}
    >
      {underlineTitle && !pageEditing ? (
          title?.value && (
              <>
                {title.value.includes('||') ? (
                    <headingTag
                        className={titleClass}
                        dangerouslySetInnerHTML={{
                          __html: `${underlineText(
                              `<span class="small-pre-heading">${title.value.split('||')[0]}</span>`,
                              {
                                openTag: '<u>',
                                closeTag: '</u>',
                              }
                          )}`,
                        }}
                    />
                ) : (
                    <headingTag
                        className={titleClass}
                        dangerouslySetInnerHTML={{
                          __html: underlineText(title.value, {
                            openTag: '<u>',
                            closeTag: '</u>',
                          }),
                        }}
                    />
                )}
              </>
          )
      ) : (
          title?.value && (
              title.value.includes('||') ? (
                  <div className="heading-with-sm-top">
                    <span className="small-pre-heading">{title.value.split('||')[0]}</span>
                    <h2>{title.value.split('||')[1]}</h2>
                  </div>
              ) : (
                  <Text field={title} tag={headingTag} className={`${titleClass} ${headingClass}`} />
              )
          )
      )}
      {text?.value && <RichText tag="p" field={text} />}
      {copy?.value && (
          <div className={`p ${textClass}`} dangerouslySetInnerHTML={{ __html: copy.value }} />
      )}
      {children}
      {!isServer() && cta && ctaVisible && (
        <Button
          text={cta?.text}
          href={cta?.href}
          variant={ctaColor || variant}
          onClick={onClick}
          {...cta?.value}
          size={device === 'desktop' ? 'large' : 'small'}
        />
      )}
    </PromoBoxComponent>
  );
};

PromoBox.propTypes = {
  title: PropTypes.object,
  titleClass: PropTypes.string,
  titleColor: PropTypes.string,
  text: PropTypes.string,
  copy: PropTypes.string,
  copyColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  ctaColor: PropTypes.string,
  cta: PropTypes.shape({
    text: PropTypes.string,
    href: PropTypes.string,
  }),
  variant: PropTypes.oneOf(['red', 'yellow', 'brown', 'blue', 'transparent']),
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  pageEditing: PropTypes.bool,
  underlineText: PropTypes.bool,
};

export default withSitecoreContext()(PromoBox);