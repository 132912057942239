import React, { useEffect, useRef, useState } from 'react';
import {PromoWrap, HeroCarouselSlideComponent, Slide, StyledAnimatedDiv} from './HomepageHeroCarouselSlide.styles';
import { animated } from 'react-spring';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { Text } from 'components/shared/JssOverrides';
import Button from 'components/shared/Button';
import { useDeviceResize } from 'hooks';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { isExperienceEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const HomepageHeroCarouselSlide = ({
  rendering,
  key,
  props,
  setPause,
  bind,
  setCurrentSlide,
  totalSlides,
  brandHeadlineSvg = {},
  sitecoreContext,
  setMinHeight = () => {},
}) => {
  const fields = rendering?.fields;
  const params = rendering?.params;
  const experienceEditor = isExperienceEditorActive();
  const device = useDeviceResize();
  const slideRef = useRef();
  const { site } = sitecoreContext;
  const promoWrapRef = useRef();
  useEffect(() => {
    const handleChangeSize = () => {
      if (slideRef?.current) {
        setMinHeight(slideRef?.current?.offsetHeight);
      }
    };

    window.addEventListener('resize', handleChangeSize);

    return () => {
      window.removeEventListener('resize', handleChangeSize);
    };
  }, []);

  useEffect(() => {
    if (slideRef?.current) {
      setMinHeight(slideRef?.current?.offsetHeight);
    }
    if (promoWrapRef?.current) {
      promoWrapRef.current.style.height = `${promoWrapRef.current.offsetHeight}px`;
    }
  }, [setMinHeight]);

  if (experienceEditor || sitecoreContext?.pageEditing) {
    return (
      <HeroCarouselSlideComponent site={site.name} className={site.name}>
        <div className="placeholder-slide">
          {brandHeadlineSvg?.value?.src && !!params?.showBrandHeadline && (
              <div className="tagline">
                <div className="tagline-wrap">
                  <h1>That Beautiful Bean Co.</h1>
                  <Image field={brandHeadlineSvg} draggable={false}/>
                </div>
              </div>
          )}
          <div
              className={site.name === 'bushs-beans-foodservices' ? 'fs-image-container' : 'image-container'}
              style={{
                backgroundImage: `url(${fields?.backgroundMedia?.value?.src})`,
              }}
          >
            {fields?.foregroundImage?.value?.src && (
                <Image field={fields.foregroundImage} className="front-image" draggable={false}
                       alt={fields.foregroundImage.value.alt}/>
            )}
            <div
                className={`controls ${params?.contentPanelAlignment === 'right' ? 'controls-align-left' : ''}`}
            >
              <Button
                  type="circle"
                  variant={params?.arrowStyle}
                  size={device === 'desktop' ? 'large' : 'small'}
                  ariaLabel="Next slide"
                  onClick={() => {
                    setCurrentSlide(currentSlide => {
                      return currentSlide === totalSlides ? 1 : currentSlide + 1;
                    });
                    setPause(true);
                  }}
              />

              <Button
                  type="circle"
                  variant={params?.arrowStyle}
                  size={device === 'desktop' ? 'large' : 'small'}
                  ariaLabel="Previous slide"
                  onClick={() => {
                    setCurrentSlide(currentSlide => {
                      return currentSlide !== 1 ? currentSlide - 1 : totalSlides;
                    });
                    setPause(true);
                  }}
              />
            </div>
          </div>
          <PromoWrap
              site={site.name}
              className={`${site.name} ${params?.contentPanelAlignment === 'right' ? 'promo-align-right' : ''}`}
              variant={params?.contentPanelStyle}
          >

            {fields?.panelTitle?.value && (
                <Text
                    field={fields.panelTitle}
                    tag={site.name === 'bushs-beans-foodservices' ? 'h1' : 'h2'}
                />
            )}
            {fields?.panelCopy?.value && <Text field={fields.panelCopy} tag="p"/>}
            {fields?.panelCta?.value && (
                <Button
                    variant="secondary"
                    text={fields.panelCta.value?.text}
                    href={fields.panelCta.value?.href}
                    target={fields.panelCta.value?.target}
                ></Button>
            )}
          </PromoWrap>
        </div>
      </HeroCarouselSlideComponent>
    );
  }

  return (
      <StyledAnimatedDiv
          {...bind()}
          key={key}
          style={props}
          data-site={site.name}
          className={`slide-wrapper ${site.name}-slide-wrapper`}
      >
      <Slide className="slide" ref={slideRef}>
        {site && site.name !== 'bushs-beans-foodservices' && (
            <>
              {brandHeadlineSvg?.value?.src && !!params?.showBrandHeadline && (
                  <div className="tagline">
                    <div className="tagline-wrap">
                      <h1 className="tagline-h1">That <span>Beautiful</span> Bean Co.</h1>
                      <Image field={brandHeadlineSvg} draggable={false}/>
                    </div>
                  </div>
              )}
            </>
        )}
        <animated.div
            className={site.name === 'bushs-beans-foodservices' ? 'fs-image-container' : 'image-container'}
          style={{
            backgroundImage: `url(${fields?.backgroundMedia?.value?.src})`,
          }}
          onMouseEnter={() => {
            setPause(true);
          }}
          onMouseLeave={() => {
            setPause(false);
          }}
        >
          {fields?.foregroundImage?.value?.src && (
            <img src={fields?.foregroundImage?.value?.src} className="front-image" draggable={false} alt={fields.foregroundImage.value.alt} />
          )}
          {totalSlides > 1 && !isServer() && (
            <div
              className={`controls ${params?.contentPanelAlignment === 'right' ? 'controls-align-left' : ''}`}
            >
              <Button
                type="circle"
                icon="chevron-left"
                variant={params?.arrowStyle}
                size={device === 'desktop' ? 'large' : 'small'}
                ariaLabel="Next slide"
                onClick={() => {
                  setCurrentSlide(currentSlide => {
                    return currentSlide === totalSlides ? 1 : currentSlide + 1;
                  });
                  setPause(true);
                }}
              />

              <Button
                type="circle"
                icon="chevron-right"
                variant={params?.arrowStyle}
                size={device === 'desktop' ? 'large' : 'small'}
                ariaLabel="Previous slide"
                onClick={() => {
                  setCurrentSlide(currentSlide => {
                    return currentSlide !== 1 ? currentSlide - 1 : totalSlides;
                  });
                  setPause(true);
                }}
              />
            </div>
          )}
        </animated.div>
        {!!(fields?.panelTitle?.value || fields?.panelCopy?.value || fields?.panelCta?.value?.href) && (
            <PromoWrap
                ref={promoWrapRef}
                site={site.name}
                className={`${params?.contentPanelAlignment === 'right' ? 'promo-align-right' : ''} ${site.name === 'bushs-beans-foodservices' ? 'foodservice' : ''}`}
                variant={params?.contentPanelStyle}
                setMinHeight={setMinHeight}
            >
              {fields?.panelTitle?.value && (
                  <Text
                      field={fields.panelTitle}
                      tag={site.name === 'bushs-beans-foodservices' ? 'h1' : 'h2'}
                  />
              )}
            {fields?.panelCopy?.value && <Text field={fields.panelCopy} tag="p" />}
            {fields?.panelCta?.value && (
              <Button
                variant={site.name === 'bushs-beans-foodservices' ? 'brown' : 'secondary'}
                text={fields.panelCta.value?.text}
                href={fields.panelCta.value?.href}
                target={fields.panelCta.value?.target}
              ></Button>
            )}
          </PromoWrap>
        )}
      </Slide>
    </StyledAnimatedDiv>
  );
};

export default withSitecoreContext()(HomepageHeroCarouselSlide);